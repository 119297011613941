import React, {useEffect, useState} from "react";
import {LanguageSupportRepository} from "../../repository/LanguageSupportRepository";
import {
    Box, Breadcrumbs,
    Button,
    Chip,
    IconButton,
    Stack,
    Table,
    TableCell,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import {MainLayout} from "../../layouts/MainLayout";
import {paginationInitialState} from "../../consts/paginationState";
import {grey, teal} from "@mui/material/colors";
import * as Flags from "country-flag-icons/react/3x2";
import {ArrowBackIos, Delete, EditNote, Remove} from "@mui/icons-material";
import {CreateLanguageModal} from "../Languages/CreateLanguageModal";
import AddIcon from "@mui/icons-material/Add";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../components/ConfirmDialog";
import {toast} from "react-toastify";
import {CardTable} from "../../components/table/CardTable";
import {CreateDataset} from "./CreateDataset";
import {DatasetRepository} from "../../repository/DatasetRepository";
import {Link, useParams} from "react-router-dom";
import {DocumentRepository} from "../../repository/DocumentRepository";
import {CreateDocumentsModal} from "./CreateDocumentsModal";

export const DatasetDocuments = () => {
    const [openCreateTranslationsModal, setOpenCreateTranslationsModal] = useState(false);
    const [dataset, setDataset] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [pagination, setPagination] = useState({...paginationInitialState});

    const {id} = useParams();

    useEffect(() => {
        if (id){
            fetchPaged();
            fetchDataset();
        }
    }, [id, pagination.page, pagination.size]);

    const fetchDataset = () => {
        DatasetRepository.get(id)
            .then(response => setDataset(response.data))
            .catch(error => toast.error(error.message));
    }

    const fetchPaged = () => {
        DocumentRepository.getPaged(pagination.page, pagination.size, id)
            .then(response => {
                setDocuments(response.data.content);
                setPagination((prevState) => {
                    return {
                        ...prevState,
                        totalPages: response.data.totalPages,
                        totalElements: response.data.totalElements
                    }
                });
            })
            .catch(error => console.log(error));
    }

    const deleteDocument = async (document) => {
        const response = await confirm(`Are you sure you want to delete ${document?.text} document?`, confirmDialogOptions);
        if (response) {
            DocumentRepository.delete(document?.id)
                .then(() => {
                    fetchPaged();
                    toast.success(`Document deleted successfully.`);
                })
                .catch(error => toast.error(error.message));
        }
    }

    const rows = documents?.map(document => {
        const Flag = Flags[document?.language?.icon];
        const stateColor = {
            "NEW": "primary",
            "UPDATED": "warning",
            "FINISHED": "success"
        }[document.state];
        return [
            <Flag height={20} />,
            document?.text,
            <Chip size={"small"} label={document?.state?.toLowerCase()} color={stateColor || "default"} sx={{textTransform: "capitalize"}} />,
            <IconButton
                color={"error"}
                onClick={() => deleteDocument(document)}
            >
                <Delete/>
            </IconButton>,
            <Button
                component={Link}
                to={`/dataset/${id}/document/${document?.id}`}
            >
                OPEN
            </Button>
        ]
    })

    const secondaryHeader = (
        <Button
            onClick={() => {
                setOpenCreateTranslationsModal(true);
            }}
            size={"small"}
            sx={{
                mx: 2,
                px: 1,
                display: {xs: "none", md: "inline-flex"},
                bgcolor: teal[300],
                color: teal[50],
                "&:hover": {
                    bgcolor: teal[50],
                    color: teal[300],
                    boxShadow: 0
                }
            }}
            variant={"contained"}
        >
            <AddIcon sx={{fontSize: 20}} /> <Typography fontSize={15} textTransform={"capitalize"}>Insert dataset</Typography>
        </Button>
    )

    const footerSecondary = (
        <TablePagination
            sx={{p: 0, m: 0}}
            component="div"
            count={pagination.totalPages}
            page={pagination.page}
            onPageChange={(event, value) => setPagination(prevState => {
                return {...prevState, page: value}
            })}
            rowsPerPage={pagination.size}
            rowsPerPageOptions={[10, 50, 100, 500]}
            onRowsPerPageChange={(event) => {
                setPagination(prevState => {
                    return {
                        ...prevState,
                        size: parseInt(event.target.value, 10)
                    }
                });
            }}
        />
    );

    return (
        <MainLayout>
            <CreateDocumentsModal
                open={openCreateTranslationsModal}
                handleClose={() => {
                    setOpenCreateTranslationsModal(false);
                }}
                id={id}
                handleSuccess={() => {
                    toast.success(`Dataset saved successfully.`);
                    setOpenCreateTranslationsModal(false)
                    fetchPaged();
                }}
            />
            <CardTable
                headerTitle={(
                    <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                        <Typography sx={{fontWeight: "bold"}}>{dataset?.name}</Typography>
                        <Breadcrumbs aria-label="breadcrumb" sx={{fontSize: 12}}>
                            <Link underline="hover" color={"primary"} to={"/dataset"} sx={{fontSize: 12}}>
                                datasets
                            </Link>
                            <Typography color="text.primary" sx={{fontSize: 12}}>{dataset?.name}</Typography>
                        </Breadcrumbs>
                    </Stack>
                )}
                secondaryHeader={secondaryHeader}
                footerSecondary={footerSecondary}
                tableHeader={["Language", "Text", "State", "Delete", ""]}
                rows={rows}
            />
        </MainLayout>
    )
}