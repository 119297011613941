import React, {useEffect, useState} from "react";
import {LanguageSupportRepository} from "../../repository/LanguageSupportRepository";
import {Box, Button, IconButton, Stack, Table, TableCell, TablePagination, TableRow, Typography} from "@mui/material";
import {MainLayout} from "../../layouts/MainLayout";
import {paginationInitialState} from "../../consts/paginationState";
import {grey, teal} from "@mui/material/colors";
import * as Flags from "country-flag-icons/react/3x2";
import {Delete, EditNote, Remove} from "@mui/icons-material";
import {CreateLanguageModal} from "./CreateLanguageModal";
import AddIcon from "@mui/icons-material/Add";
import {confirm} from "react-confirm-box";
import {confirmDialogOptions} from "../../components/ConfirmDialog";
import {toast} from "react-toastify";
import {CardTable} from "../../components/table/CardTable";

export const Languages = () => {
    const [editLanguage, setEditLanguage] = useState(null);
    const [openLanguageModal, setOpenLanguageModal] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [pagination, setPagination] = useState({...paginationInitialState});

    useEffect(() => {
        fetchPaged();
    }, [pagination.page, pagination.size]);

    const fetchPaged = () => {
        LanguageSupportRepository.getPaged(pagination.page, pagination.size)
            .then(response => {
                setLanguages(response.data.content);
                setPagination((prevState) => {
                    return {
                        ...prevState,
                        totalPages: response.data.totalPages,
                        totalElements: response.data.totalElements
                    }
                });
            })
            .catch(error => console.log(error));
    }

    const deleteLanguage = async (language) => {
        const response = await confirm(`Are you sure you want to delete ${language?.language} language?`, confirmDialogOptions);
        if (response) {
            LanguageSupportRepository.delete(language?.id)
                .then(() => {
                    fetchPaged();
                    toast.success(`Language deleted successfully.`);
                })
                .catch(error => toast.error(error.message));
        }
    }

    const rows = languages?.map(language => {
        const Flag = Flags[language?.icon];
        return [
            <Flag height={20} />,
            language.language,
            language?.code,
            language?.nmtCode,
            <IconButton
                color={"primary"}
                onClick={() => {
                    setEditLanguage(language);
                    setOpenLanguageModal(true);
                }}
            >
                <EditNote/>
            </IconButton>,
            <IconButton
                color={"error"}
                onClick={() => deleteLanguage(language)}
            >
                <Delete/>
            </IconButton>
        ]
    })

    const secondaryHeader = (
        <Button
            onClick={() => {
                setEditLanguage(null);
                setOpenLanguageModal(true);
            }}
            size={"small"}
            sx={{
                mx: 2,
                px: 1,
                display: {xs: "none", md: "inline-flex"},
                bgcolor: teal[300],
                color: teal[50],
                "&:hover": {
                    bgcolor: teal[50],
                    color: teal[300],
                    boxShadow: 0
                }
            }}
            variant={"contained"}
        >
            <AddIcon sx={{fontSize: 20}} /> <Typography fontSize={15} textTransform={"capitalize"}>Add Language</Typography>
        </Button>
    )

    const footerSecondary = (
        <TablePagination
            sx={{p: 0, m: 0}}
            component="div"
            count={pagination.totalPages}
            page={pagination.page}
            onPageChange={(event, value) => setPagination(prevState => {
                return {...prevState, page: value}
            })}
            rowsPerPage={pagination.size}
            rowsPerPageOptions={[10, 50, 100, 500]}
            onRowsPerPageChange={(event) => {
                setPagination(prevState => {
                    return {
                        ...prevState,
                        size: parseInt(event.target.value, 10)
                    }
                });
            }}
        />
    );

    return (
        <MainLayout>
            <CreateLanguageModal
                open={openLanguageModal}
                language={editLanguage}
                handleClose={() => {
                    setEditLanguage(null);
                    setOpenLanguageModal(false);
                }}
                handleSuccess={() => {
                    toast.success(`Language saved successfully.`);
                    setOpenLanguageModal(false)
                    setEditLanguage(null);
                    fetchPaged();
                }}
            />
            <CardTable
                headerTitle={<Typography sx={{fontWeight: "bold"}}>Languages</Typography>}
                secondaryHeader={secondaryHeader}
                footerSecondary={footerSecondary}
                tableHeader={["#", "Language", "Code", "NMT Code", "Edit", "Delete"]}
                rows={rows}
            />
        </MainLayout>
    )
}