import axios from "../axios/axios";

export const TranslationRepository = {
    create: (data) => {
        return axios.post(`/api/translation`, data)
    },
    getByDocument: (id) => {
        return axios.get(`/api/translation/document/${id}`)
    },
    get: (id) => {
        return axios.get(`/api/translation/${id}`)
    },
    edit: (id, data) => {
        return axios.put(`/api/translation/${id}`, data)
    },
    delete: (id) => {
        return axios.delete(`/api/translation/${id}`)
    },
    confirm: (id) => {
        return axios.patch(`/api/translation/${id}/confirm`)
    }
}