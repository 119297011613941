import React, {useEffect, useMemo, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {countries} from 'country-flag-icons'
import * as Flags from "country-flag-icons/react/3x2";
import {toast} from "react-toastify";
import {LanguageSupportRepository} from "../../repository/LanguageSupportRepository";
import {DatasetRepository} from "../../repository/DatasetRepository";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    border: 0,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export const CreateDataset = ({open, handleClose, handleSuccess, dataset}) => {
    const [datasetForm, setDatasetForm] = useState({
        name: "",
        mainLanguage: "",
        translationLanguages: []
    });
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        LanguageSupportRepository.getAll()
            .then(response => setLanguages(response.data))
            .catch(error => toast.error(error.message));
    }, [])

    useEffect(() => {
        let form = {...datasetForm};
        if (dataset) {
            form.name = dataset.name || "";
            form.mainLanguage = dataset?.language?.id || "";
            form.translationLanguages = dataset?.translations?.map(i => i?.id) || "";
        }
        setDatasetForm(form);
    }, [dataset])

    const onChange = (event) => {
        const {name, value} = event.target;
        console.log(value);

        setDatasetForm((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!datasetForm.name || !datasetForm?.mainLanguage || datasetForm?.translationLanguages?.length === 0) {
            toast.error("All fields are required!");
            return;
        }
        if (!dataset) {
            DatasetRepository.create(datasetForm)
                .then(() => handleSuccess())
                .catch(error => toast.error(error.message));
        } else {
            DatasetRepository.edit(dataset?.id, datasetForm)
                .then(() => handleSuccess())
                .catch(error => toast.error(error.message));
        }
    }

    const otherLanguages = useMemo(() => {
        return languages?.filter(language => language?.id !== datasetForm?.mainLanguage);
    }, [datasetForm?.mainLanguage])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
        >
            <Box sx={style}>
                <Stack direction={"column"} gap={2}>
                    <Typography fontWeight={"bold"}
                                fontSize={20}>{dataset ? "Edit dataset" : "Create new dataset"}</Typography>
                    <TextField
                        label={"Name"}
                        placeholder={"Name"}
                        name={"name"}
                        value={datasetForm.name}
                        onChange={onChange}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Main language</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            label={"Main Language"}
                            name={"mainLanguage"}
                            value={datasetForm.mainLanguage}
                            onChange={onChange}
                        >
                            {
                                languages?.map(language => {
                                    let Flag = null;
                                    if (language.icon) {
                                        Flag = Flags[language.icon];
                                    }
                                    return (
                                        <MenuItem value={language?.id}>
                                            {Flag && <Flag height={20} style={{marginRight: 10}}/>}{language?.language}
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multi-select-label">Translation languages</InputLabel>
                        <Select
                            labelId="demo-multi-select-label"
                            label={"Translation languages"}
                            name={"translationLanguages"}
                            value={datasetForm.translationLanguages}
                            multiple
                            onChange={onChange}
                        >
                            {
                                otherLanguages?.map(language => {
                                    let Flag = null;
                                    if (language.icon) {
                                        Flag = Flags[language.icon];
                                    }
                                    return (
                                        <MenuItem value={language?.id}>
                                            {Flag && <Flag height={20} style={{marginRight: 10}}/>}{language?.language}
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                    <Stack direction={"row"} gap={2} alignItems={"center"}>
                        <Button
                            variant={"outlined"}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button
                            color={"success"}
                            variant={"outlined"}
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    )
}
