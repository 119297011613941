import {CircularProgress, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {Navigate, Route, Routes, BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useAuth from "./hooks/useAuth";
import {LoginPage} from "./pages/Login/LoginPage";
import {RegisterPage} from "./pages/Register/RegisterPage";
import {HomePage} from "./pages/HomePage/HomePage";
import {SessionWrapper} from "./pages/Session/SessionWrapper";
import {Languages} from "./pages/Languages/Languages";
import {Dataset} from "./pages/Dataset/Dataset";
import {DatasetDocuments} from "./pages/Dataset/DatasetDocuments";
import {Document} from "./pages/Document/Document";

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      // default: "#212121"
    },
    light: {
      main: "#FFF"
    }
  },
});

function App() {
  const [isAuth, user, roles, login, logout, hasAnyRole, hasEveryRole] = useAuth();

  return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <ToastContainer
            position={"top-right"}
            autoClose={2000}
            hideProgressBar
            newestOnTop
        />

        {
          isAuth === null ?
              <div style={{ position: "absolute", top: "50%", left: "50%"}}><CircularProgress /></div> :
              <BrowserRouter>
                <Routes>
                  {
                    !isAuth ?
                        <>
                          <Route path={"/login"} element={<LoginPage />} />
                          <Route path={"/register"} element={<RegisterPage />} />
                          <Route path={"*"} element={<Navigate to={"/login"} />} />
                        </>
                        :
                        <>
                          <Route exact path={"/"} element={<HomePage />} />
                          <Route exact path={"/dataset"} element={<Dataset />} />
                          <Route exact path={"/dataset/:id"} element={<DatasetDocuments />} />
                          <Route exact path={"/dataset/:id/document/:documentId"} element={<Document />} />
                          <Route exact path={"/languages"} element={<Languages />} />
                          <Route path={"*"} element={<Navigate to={"/"} />} />
                        </>
                  }
                </Routes>
              </BrowserRouter>
        }

      </ThemeProvider>
  );
}

export default App;
