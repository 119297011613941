import React, {useEffect, useMemo, useState} from "react";
import {MainLayout} from "../../layouts/MainLayout";
import {grey} from "@mui/material/colors";
import {Box, Breadcrumbs, IconButton, Stack, TextField, Tooltip, Typography} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {DatasetRepository} from "../../repository/DatasetRepository";
import {toast} from "react-toastify";
import {DocumentRepository} from "../../repository/DocumentRepository";
import {TranslationRepository} from "../../repository/TranslationRepository";
import * as Flags from "country-flag-icons/react/3x2";
import {CheckTwoTone, RestartAlt, SwapHoriz, SwapHorizontalCircle} from "@mui/icons-material";
import useWebSocket from "../../hooks/useWebSocket";

export const Document = () => {
    const [dataset, setDataset] = useState(null);
    const [document, setDocument] = useState(null);
    const [translations, setTranslations] = useState([]);

    const {id, documentId} = useParams();

    const {connected, receivedMessage} = useWebSocket(documentId)

    useEffect(() => {
        fetchDataset();
    }, [id])

    useEffect(() => {
        fetchDocument();
        fetchTranslations();
    }, [documentId])

    useEffect(() => {
        if (receivedMessage) {
            const items = [...translations];
            const itemIndex = translations?.findIndex(item => item?.id === receivedMessage?.id);
            if (isFinite(itemIndex) && itemIndex >= 0) {
                items[itemIndex] = receivedMessage;
                setTranslations(items);
            }
        }
    }, [receivedMessage])

    const fetchDataset = () => {
        DatasetRepository.get(id)
            .then(response => setDataset(response.data))
            .catch(error => toast.error(error.message));
    }
    const fetchDocument = () => {
        DocumentRepository.get(documentId)
            .then(response => setDocument(response.data))
            .catch(error => toast.error(error.message));
    }
    const fetchTranslations = () => {
        TranslationRepository.getByDocument(documentId)
            .then(response => setTranslations(response.data))
            .catch(error => toast.error(error.message));
    }

    const onDocumentUpdate = (text) => {
        DocumentRepository.edit(documentId, { text, dataset: id })
            .then(response => {
                toast.success("Document updated successfully");
                setDocument(response.data);
            })
            .catch(error => toast.error(error.message));
    }

    const updateTranslation = (translation) => {
        setTranslations(prevState => {
            const items = prevState.map(item => {
                if (item.id === translation.id) {
                    return translation;
                }
                return item;
            })
            return items;
        })
    }
    const regenerateTranslations = (translation) => {
        DocumentRepository.regenerate(document?.id)
            .then(response => {
                setTranslations(response.data);
            })
            .catch(error => console.log(error))
    }

    return (
        <MainLayout>
            <Stack direction={"column"} sx={{
                width: "100%",
                height: "100%",
                bgcolor: "#FFF",
                borderTopLeftRadius: 15,
                border: 1,
                borderColor: grey[50]
            }}>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"} sx={{width: "100%", p: 2,}}>
                    <Box>
                        <Stack direction={"column"} gap={1} alignItems={"flex-start"}>
                            <Typography sx={{fontWeight: "bold"}}>{dataset?.name}</Typography>
                            <Breadcrumbs aria-label="breadcrumb" sx={{fontSize: 12}}>
                                <Link underline="hover" color={"primary"} to={"/dataset"} sx={{fontSize: 12}}>
                                    datasets
                                </Link>
                                <Link underline="hover" color={"primary"} to={`/dataset/${dataset?.id}`} sx={{fontSize: 12}}>
                                    {dataset?.name}
                                </Link>
                                <Typography color="text.primary" sx={{fontSize: 12}}>{document?.id}</Typography>
                            </Breadcrumbs>
                        </Stack>
                    </Box>
                    <Stack direction={"row"} gap={1}>
                        <Tooltip title={"Regenerate translations"}>
                            <IconButton onClick={regenerateTranslations}>
                                <RestartAlt />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
                <Box sx={{flex: 1, borderBottom: 1, borderTop: 1, borderColor: "divider", maxWidth: "100%", overflowY: "auto"}}>
                    <Stack direction={"column"} gap={2} sx={{ p: 2}}>
                        <Stack direction={"row"} gap={1}>
                            <EditableText
                                text={document?.text}
                                onSubmit={(text) => onDocumentUpdate(text)}
                            />
                        </Stack>
                        {
                            translations?.map(translation => {
                                return (
                                    <Translation
                                        key={translation?.id}
                                        translation={translation}
                                        updateTranslation={updateTranslation}
                                    />
                                )
                            })
                        }
                    </Stack>
                </Box>
            </Stack>
        </MainLayout>
    )
}

const Translation = ({ translation, updateTranslation }) => {
    const [text, setText] = useState("");


    const onUpdate = (newText) => {
        TranslationRepository.edit(translation?.id, {text: newText})
            .then(response => {
                toast.success("Translation updated successfully");
                updateTranslation(response.data);
            })
            .catch(error => toast.error(error.message));
    }

    const onConfirm = () => {
        TranslationRepository.confirm(translation?.id)
            .then(response => {
                toast.success("Translation confirmed");
                updateTranslation(response.data);
            })
            .catch(error => toast.error(error.message));
    }

    const {From, To} = useMemo(() => {
        const From = Flags[translation?.from?.icon] || null;
        const To = Flags[translation?.to?.icon] || null;

        return {From, To}
    }, [translation])

    return (
        <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Box>
                { From && <From height={20} /> }
                <SwapHoriz sx={{ color: grey[700] }} />
                { To && <To height={20} /> }
            </Box>
            {
                translation?.translation ?
                    <>
                        <EditableText
                            text={translation?.translation}
                            onSubmit={(newText) => onUpdate(newText)}
                        />
                        <IconButton
                            color={"success"}
                            disabled={translation?.confirmed}
                            onClick={onConfirm}
                        >
                            <CheckTwoTone />
                        </IconButton>
                    </>
                    :
                    <>
                        <TextField
                            fullWidth
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                        />
                        <IconButton
                            color={"success"}
                            disabled={!text}
                            onClick={() => onUpdate(text)}
                        >
                            <CheckTwoTone />
                        </IconButton>
                    </>

            }

        </Stack>
    )
}

const EditableText = ({ text, onSubmit }) => {
    const [contentEditable, setContentEditable] = useState(false);

    const onBlur = (event) => {
        event.preventDefault();
        const newText = event.target.innerText;
        if (contentEditable && newText !== text) {
            onSubmit(newText);
            setContentEditable(false);
        }
    }

    return (
        <Typography
            onDoubleClick={() => setContentEditable(true)}
            contentEditable={contentEditable}
            suppressContentEditableWarning
            onBlur={onBlur}
            sx={{p: 1, flex: 1}}
        >
            {text}
        </Typography>
    )
}