import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {countries} from 'country-flag-icons'
import * as Flags from "country-flag-icons/react/3x2";
import {toast} from "react-toastify";
import {LanguageSupportRepository} from "../../repository/LanguageSupportRepository";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    border: 0,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export const CreateLanguageModal = ({open, handleClose, handleSuccess, language}) => {
    const [languageForm, setLanguageForm] = useState({
        icon: "",
        language: "",
        code: "",
        nmtCode: ""
    });

    useEffect(() => {
        let form = {...languageForm};
        if (language) {
            form = {...language}
        }
        setLanguageForm(form);
    }, [language])

    const onChange = (event) => {
        const {name, value} = event.target;

        setLanguageForm((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!languageForm.icon || !languageForm.language || !languageForm.code || !languageForm.nmtCode) {
            toast.error("All fields are required!");
            return;
        }
        if (language) {
            LanguageSupportRepository.edit(language?.id, languageForm)
                .then(() => handleSuccess())
                .catch(error => toast.error(error.message))
                .finally(() =>{
                    setLanguageForm({
                        icon: "",
                        language: "",
                        code: "",
                        nmtCode: "",
                    })
                })
        } else {
            LanguageSupportRepository.create(languageForm)
                .then(() => handleSuccess())
                .catch(error => toast.error(error.message))
                .finally(() =>{
                    setLanguageForm({
                        icon: "",
                        language: "",
                        code: "",
                        nmtCode: "",
                    })
                })
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
        >
            <Box sx={style}>
                <Stack direction={"column"} gap={2}>
                    <Typography fontWeight={"bold"} fontSize={20}>{ language ? "Edit language" : "Create new language"}</Typography>
                    <TextField
                        label={"Language"}
                        placeholder={"Language"}
                        name={"language"}
                        value={languageForm.language}
                        onChange={onChange}
                    />
                    <TextField
                        label={"Code"}
                        placeholder={"Code"}
                        name={"code"}
                        value={languageForm.code}
                        onChange={onChange}
                    />
                    <TextField
                        label={"NMT code"}
                        placeholder={"NMT code"}
                        name={"nmtCode"}
                        value={languageForm.nmtCode}
                        onChange={onChange}
                    />
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Icon</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            label={"Icon"}
                            name={"icon"}
                            value={languageForm.icon}
                            onChange={onChange}
                        >
                            {countries?.map(country => {
                                const Flag = Flags[country];
                                return (
                                    <MenuItem value={country}><Flag height={20} style={{marginRight: 10}}/>{country}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <Stack direction={"row"} gap={2} alignItems={"center"}>
                        <Button
                            variant={"outlined"}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button
                            color={"success"}
                            variant={"outlined"}
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    )
}
