import axios from "../axios/axios";

export const DatasetRepository = {
    create: (data) => {
        return axios.post(`/api/dataset`, data)
    },
    getPaged: (page = 0, size = 10) => {
        return axios.get(`/api/dataset/?page=${page}&size=${size}`)
    },
    get: (id) => {
        return axios.get(`/api/dataset/${id}`)
    },
    edit: (id, data) => {
        return axios.put(`/api/dataset/${id}`, data)
    },
    delete: (id) => {
        return axios.delete(`/api/dataset/${id}`)
    }
}