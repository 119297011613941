import axios from "../axios/axios";

export const LanguageSupportRepository = {
    create: (data) => {
        return axios.post(`/api/language-support`, data)
    },
    getAll: () => {
        return axios.get(`/api/language-support/all`)
    },
    getPaged: (page = 0, size = 10) => {
        return axios.get(`/api/language-support/?page=${page}&size=${size}`)
    },
    edit: (id, data) => {
        return axios.put(`/api/language-support/${id}`, data)
    },
    delete: (id) => {
        return axios.delete(`/api/language-support/${id}`)
    }
}