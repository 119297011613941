import React from "react";
import {grey} from "@mui/material/colors";
import {Box, Stack, Table, TableCell, TableRow, Typography} from "@mui/material";


export const CardTable = ({ headerTitle, secondaryHeader, tableHeader, rows, footerPrimary, footerSecondary }) => {

    return (
        <Stack direction={"column"} sx={{
            width: "100%",
            height: "100%",
            bgcolor: "#FFF",
            borderTopLeftRadius: 15,
            border: 1,
            borderColor: grey[50]
        }}>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "100%", p: 2,}}>
                <Box>
                    <Typography sx={{fontWeight: "bold"}}>
                        {headerTitle}
                    </Typography>
                </Box>
                <Box>
                    {secondaryHeader}
                </Box>
            </Stack>
            <Box sx={{flex: 1, borderBottom: 1, borderTop: 1, borderColor: "divider", maxWidth: "100%", overflowY: "auto"}}>
                <Table>
                    <TableRow>
                        {
                            tableHeader?.map(header => <TableCell key={header} sx={{fontWeight: "bold"}}>{header}</TableCell>)
                        }
                    </TableRow>
                    {
                        rows.map(row => {
                            return (
                                <TableRow>
                                    {row?.map(cell => <TableCell>{cell}</TableCell>)}
                                </TableRow>
                            )
                        })
                    }
                </Table>
            </Box>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{width: "100%"}}>
                <Box>
                    {footerPrimary}
                </Box>
                <Box>
                    {footerSecondary}
                </Box>
            </Stack>
        </Stack>
    )
}