import React, {useState} from "react";
import {
    AppBar,
    Avatar,
    Box, Button, ButtonBase,
    CssBaseline,
    Drawer,
    IconButton,
    List, ListItem, ListItemButton, ListItemIcon, ListItemText,
    Menu,
    MenuItem, Stack,
    Toolbar,
    Tooltip
} from "@mui/material";
import logo from "../assets/logo.png";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {blue, blueGrey, grey, teal} from '@mui/material/colors';
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import MenuIcon from '@mui/icons-material/Menu';
import {toast} from "react-toastify";
import {Dataset, Home, Language, Storage} from "@mui/icons-material";
import {CreateLanguageModal} from "../pages/Languages/CreateLanguageModal";

export const drawerWidth = 260;

const navLinks = [
    {path: "/", name: "Home", icon: <Home/>},
    {path: "/dataset", name: "Dataset", icon: <Storage/>},
    {path: "/languages", name: "Languages", icon: <Language/>},
]

export const MainLayout = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);

    return <Box sx={{display: 'flex', height: "100%", overflowX: "hidden"}}>
        <CssBaseline/>
        <Header toggleMenu={() => setIsOpen(!isOpen)}/>
        <Drawer
            variant={"permanent"}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                border: 0,
                display: {xs: "none", md: "flex"},
                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box', border: 0},
            }}
        >
            <DrawerItems/>
        </Drawer>
        <Drawer
            variant={"temporary"}
            onClose={() => setIsOpen(false)}
            open={isOpen}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                border: 0,
                display: {xs: "flex", md: "none"},
                [`& .MuiDrawer-paper`]: {width: drawerWidth, boxSizing: 'border-box', border: 0},
            }}
        >
            <DrawerItems/>
        </Drawer>
        <Box component="main" sx={{flexGrow: 1, ms: 2, mt: 2, height: "calc(100% - 80px)"}}>
            <Toolbar/>
            <Box sx={{
                height: '100%',
                maxWidth: `100vw`,
                overflowY: "auto",
                pl: 3,
                pt: 3,
                // borderRadius: 3,
                border: 1,
                borderColor: '#F1F1F1',
                borderRadius: 3,
                borderTopRightRadius: 0,
                bgcolor: grey[100]
            }}>
                {/*<Box sx={{bgcolor: "#FFF", width: "100%", height: "100%", borderRadius: 2}}>*/}
                {children}
                {/*</Box>*/}
            </Box>
        </Box>
    </Box>
}

const DrawerItems = () => {
    return (
        <>
            <Toolbar/>
            <Box id={"sidebar"} sx={{overflow: 'auto', border: 0}}>
                <List sx={{border: 0}}>
                    {navLinks.map((item, index) => (
                        <ListItem key={item.name}
                                  sx={item.name === "Create Survey" ? {display: {xs: "none", md: "inline-flex"}} : null}
                                  disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to={item.path}
                            >
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>

            </Box>
        </>
    )
}

const Header = ({toggleMenu}) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const navigate = useNavigate();
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = () => {
        handleCloseUserMenu();
        // signOut(auth).then(() => {
        //     navigate('/login')
        // }).catch((error) => {
        //     toast.error("Something went wrong")
        // });
    }

    return (
        <AppBar
            position="fixed"
            color={"inherit"}
            elevation={0}
            sx={{zIndex: (theme) => theme.zIndex.drawer + 1, width: "100%", pt: 1}}
        >
            <Toolbar disableGutters>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}
                       sx={{width: "100%", px: 2}}>
                    <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={1}>
                        <img src={logo} alt={"logo"} style={{maxWidth: "50px"}} width={"100%"} height={"auto"}/>
                        {/*<Typography variant={"h5"} fontWeight={"bold"}>*/}
                        {/*    OpenBrain*/}
                        {/*</Typography>*/}
                        <Box sx={{m: "auto", display: {xs: "flex", md: "none"}}}>
                            <IconButton sx={{my: "auto"}} onClick={toggleMenu}>
                                <MenuIcon/>
                            </IconButton>
                        </Box>
                    </Stack>
                    <Box>
                        <Tooltip title="Open settings">
                            <ButtonBase onClick={handleOpenUserMenu} sx={{p: 0, mx: 2}}>
                                <Avatar sx={{bgcolor: blue[100], color: blue[500]}}>
                                </Avatar>
                            </ButtonBase>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem onClick={logout}>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Stack>
            </Toolbar>
        </AppBar>

    )
}
