import {Button, Dialog, DialogActions, DialogTitle} from "@mui/material";

export const confirmDialogOptions = {
    render: (message, onConfirm, onCancel) => {
        return (
            <Dialog
                open={message}
            >
                <DialogTitle id="alert-dialog-title">
                    {message}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={onCancel}>Close</Button>
                    <Button onClick={onConfirm} autoFocus>Submit</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
