import React, {useEffect, useMemo, useState} from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {countries} from 'country-flag-icons'
import * as Flags from "country-flag-icons/react/3x2";
import {toast} from "react-toastify";
import {LanguageSupportRepository} from "../../repository/LanguageSupportRepository";
import {DatasetRepository} from "../../repository/DatasetRepository";
import {DocumentRepository} from "../../repository/DocumentRepository";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    border: 0,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export const CreateDocumentsModal = ({open, handleClose, handleSuccess, id}) => {
    const [text, setText] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        if (!text) {
            toast.error("Please insert some text!");
            return;
        }
        DocumentRepository.create({ text, dataset: id })
            .then(() => handleSuccess())
            .catch(error => toast.error(error.message));

    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
        >
            <Box sx={style}>
                <Stack direction={"column"} gap={2}>
                    <Typography fontWeight={"bold"}
                                fontSize={20}>Create new documents</Typography>
                    <TextField
                        label={"Text"}
                        placeholder={"Enter some text here..."}
                        name={"text"}
                        value={text}
                        multiline
                        rows={4}
                        maxRows={4}
                        onChange={(e) => setText(e.target.value)}
                    />

                    <Stack direction={"row"} gap={2} alignItems={"center"}>
                        <Button
                            variant={"outlined"}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button
                            color={"success"}
                            variant={"outlined"}
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
    )
}
