import axios from "../axios/axios";

export const DocumentRepository = {
    create: (data) => {
        return axios.post(`/api/document`, data)
    },
    getPaged: (page = 0, size = 10, id) => {
        return axios.get(`/api/document/dataset/${id}?page=${page}&size=${size}`)
    },
    get: (id) => {
        return axios.get(`/api/document/${id}`)
    },
    edit: (id, data) => {
        return axios.put(`/api/document/${id}`, data)
    },
    regenerate: (id) => {
        return axios.put(`/api/document/${id}/regenerate`)
    },
    delete: (id) => {
        return axios.delete(`/api/document/${id}`)
    }
}