import {useEffect, useState} from "react";
import {Client} from "@stomp/stompjs";
import SockJS from 'sockjs-client';
import {AUTH_TOKEN} from "../axios/axios";
import {json} from "react-router-dom";

const useWebSocket = (id) => {
    const [receivedMessage, setReceivedMessage] = useState(null);
    const [websocket, setWebsocket] = useState(null);
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        let client = null;
        if (id) {
            let onConnected = () => {
                // console.log(`Connected to websocket`)
                setConnected(true)
                try {
                    console.log("Connected");
                    client.subscribe(`/topic/document-translation/${id}`, (msg) => {
                        if (msg?.body) {
                            var jsonBody = JSON.parse(msg.body);
                            if (jsonBody) {
                                setReceivedMessage(jsonBody);
                                console.log("WS message received: ", jsonBody)
                            }
                        }
                    });

                } catch (error) {
                    console.log("ERROR");
                    setConnected(false);
                }

            }

            let onDisconnected = () => {
                // console.log("Disconnected!!")
                setConnected(false)
                setWebsocket(null);
            }

            let SOCKET_URL = process.env.REACT_APP_WS;
            client = new Client({
                brokerURL: `${SOCKET_URL}?jwt=${localStorage.getItem(AUTH_TOKEN)}`,
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,
                onConnect: onConnected,
                onDisconnect: onDisconnected,
                onStompError: () => {
                    // console.log(`Failed to connect to topic (Stomp error): ${SOCKET_URL}`)
                    setConnected(false)
                },
                onWebSocketError: () => {
                    // console.log(`Failed to connect to topic (web socket error): ${SOCKET_URL}`);
                    setConnected(false)
                }
            });

            client.activate();
            setWebsocket(client);
        }

        return () => {
            if (client) {
                // client?.disconnect();
                client?.unsubscribe();
                client = null;
                setConnected(false)
            }
        }
    }, [id]);

    return {connected, receivedMessage};
};

export default useWebSocket;