import React, {useState} from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Toolbar,
    Typography
} from "@mui/material";
import {blue, grey, purple, teal} from "@mui/material/colors";
import languages from "../../assets/languages.png";
import logo from "../../assets/logo.png";
import {Keyboard, Logout, OpenInNew} from "@mui/icons-material";
import {AUTH_TOKEN} from "../../axios/axios";
import {useNavigate} from "react-router-dom";
import {SessionRepository} from "../../repository/SessionRepository";
import {toast} from "react-toastify";
import {MainLayout} from "../../layouts/MainLayout";

export const HomePage = () => {
    const [code, setCode] = useState("");

    const createSession = () => {
        SessionRepository.create()
            .then(response => {
                const url = `${process.env.REACT_APP_CLIENT}/meet/${response.data?.id}`;
                openSession(url);
            })
            .catch(error => toast.error(error.message))
    }

    const openMeeting = (event) => {
        event?.preventDefault();
        if (code) {
            // if User paste URL replace https://localhost:3000
            const url = `${process.env.REACT_APP_CLIENT}/meet/`;
            const uid = code
                .replaceAll(url, '')
                .replaceAll("/", "");
            openSession(url + uid);
        }
    }

    const openSession = (url) => {
        window.open(url, "_blank");
    }

    return (
        <MainLayout>
            <Grid container sx={{height: "100%"}}>
                <Grid item xs={12} md={7} lg={6}>
                    <Stack direction={"column"} sx={{width: "100%", height: "100%", p: 3}} gap={2}
                           justifyContent={"center"} alignItems={"flex-start"}>
                        <Box>
                            <Typography sx={{fontFamily: "serif !important", py: 0}} fontSize={40} color={grey[800]}
                                        fontWeight={"bold"}>
                                OpenBrain Meet:
                            </Typography>
                            <Typography sx={{fontFamily: "serif !important", py: 0}} fontSize={30} color={grey[800]}
                                        fontWeight={"bold"}>
                                Premium multi language voice meetings.
                            </Typography>
                            <Typography sx={{fontFamily: "serif !important", py: 0}} fontSize={20} color={grey[700]}>
                                OpenBrain Meet is a revolutionary communication application designed to bridge language barriers and foster seamless multilingual conversations. With its intuitive interface and advanced language translation technology, this app enables users to effortlessly communicate with people from around the world, regardless of their native languages.
                            </Typography>
                            <Typography sx={{fontFamily: "serif !important", py: 0}} fontSize={20} color={grey[700]}>
                                One of the standout features of OpenBrain Meet is its real-time translation capability. Users can speak in their preferred language, and the app instantly translates their words into the chosen language of the listeners. This groundbreaking functionality facilitates fluid communication, breaking down the barriers imposed by linguistic differences.

                            </Typography>
                        </Box>
                        {/*<Divider>OR</Divider>*/}
                    </Stack>
                </Grid>
                <Grid item md={5} lg={6} sx={{display: {xs: "none", md: "block"}}}>
                    <Stack direction={"column"} sx={{width: "100%", height: "100%", p: 3}} gap={1}
                           justifyContent={"center"} alignItems={"center"}>
                        <img src={languages} alt={"languages"} style={{maxWidth: "500px"}} width={"100%"}
                             height={"auto"}/>
                    </Stack>
                </Grid>
            </Grid>
        </MainLayout>
    )
}